<template>
  <div class="clinical_box">
    <back-step v-if="isShowRTD === 'false'" :goList="[{
      url: '/cases',
      name: $t('casesDetail.casesChildren.common.bl')
    }]" :currentTxt="$t('home.personal.personal.lcbl')" />
    <div class="clinical_tabs" @click="selectedClinical">
      <p class="tabs_sp" data-type="clinic" :class="tabType === 'clinic' ? 'tabs_sp_active' : ''">
        {{ $t('casesDetail.clinicalPreferences.qqlcph') }}</p>
      <p class="tabs_sp" data-type="product" :class="tabType === 'product' ? 'tabs_sp_active' : ''">
        {{ $t('casesDetail.clinicalPreferences.cptdph') }}</p>
      <p class="tabs_sp" data-type="iRTD" v-if="isShowRTD === 'true' || (RTDId && RTDId !== '200000000000000000')"
        :class="tabType === 'iRTD' ? 'tabs_sp_active' : ''">
        {{ $t('casesDetail.clinicalPreferences.RTD') }}
      </p>
    </div>
    <div class="clinical_con">
      <div class="c_con_h">
        <h5 v-if="tabType === 'clinic'">{{ $t('casesDetail.clinicalPreferences.qqlcph') }}</h5>
        <h5 v-if="tabType === 'product'">{{ $t('casesDetail.clinicalPreferences.cptdph') }}</h5>
        <h5 v-if="tabType === 'iRTD' && RTDId !== '200000000000000000'">
          {{ $t('casesDetail.clinicalPreferences.smsRTD') }}
        </h5>
        <span class="main_theme_color_333 fz14">{{ tabType === 'iRTD' && RTDId !== '200000000000000000' ?
          $t('casesDetail.clinicalPreferences.kqRTD') : tabType === 'clinic' ? $t('casesDetail.clinicalPreferences.phjs')
            : $t('casesDetail.clinicalPreferences.cplcph') }}</span>
        <!-- <span v-if="tabType !== 'iRTD'" class="c-red fz14">{{ $t('casesDetail.clinicalPreferences.qbzd') }}</span> -->
      </div>
      <ul class="c_con_ul" @change="answerChange">
        <li class="c_con_li" v-for="item0 in clinicProblemObj[tabType]" :key="item0.id">
          <h5 class="c_li_h5">
            {{ lang === 'en_US' ? item0.enContent : item0.content }}
            <span class="c__h5_sp"
              v-if="item0.type !== 'input' && item0.type !== 'text'">({{ problemObj[item0.type] }})</span>
          </h5>
          <ProblemCom :item="item0" :answerObj="answerObj" :inputAnswerObj="inputAnswerObj"
            :multipleAnswerObj="multipleAnswerObj" />
        </li>
      </ul>
    </div>
    <div class="page_foo">
      <div class="submit_btn flex-x-y-c curp" @click="saveProblem">{{ $t('casesDetail.clinicalPreferences.bc') }}</div>
    </div>
    <FullLoading v-show="isLoading" />
  </div>
</template>

<script>
import $ from 'jquery';
import BackStep from './childrenPublic/backStep';
import FullLoading from 'components/full-loading/full-loading';
import ProblemCom from '../publicCases/problemCom';
import { problemStatement, preferAnswerList, preferAnswerCreate } from 'common/api/cases';
import { getUserId, notifyMsg } from 'common/js/util';

export default {
  data() {
    return {
      isLoading: true,
      tabType: 'clinic', // clinic 全球临床偏好  product 产品特定偏好
      clinicProblemObj: {
        clinic: [],
        product: []
      },
      answerObj: {},
      inputAnswerObj: {}, // 输入框
      multipleAnswerObj: {}, // 多选
      problemObj: {
        single: this.$t('casesDetail.clinicalPreferences.dx'),
        multiple: this.$t('casesDetail.clinicalPreferences.duox'),
        input: ''
      },
      RTDId: sessionStorage.getItem('IRTD'),
      isShowRTD: sessionStorage.getItem('isShowRTD'),
      lang: localStorage.getItem('user_lang') || 'zh_CN'
    }
  },
  created() {
    document.title = this.$t('home.personal.personal.lcbl');
    sessionStorage.setItem('proIndex', '1');
    const refType = localStorage.getItem('refType') || sessionStorage.getItem('refType') || 'doctor';
    let refId = localStorage.getItem('refId') || sessionStorage.getItem('refId');
    localStorage.removeItem('refType');
    localStorage.removeItem('refId');
    if (refType === 'doctor') {
      refId = getUserId();
    }
    sessionStorage.setItem('refType', refType);
    sessionStorage.setItem('refId', refId);
    this.getPreferAnswerList({ refType, refId });
    if (this.isShowRTD === 'true' || this.isShowRTD === true) {
      this.tabType = 'iRTD';
    }
  },
  methods: {
    answerChange(ev) {
      const target = ev.target;
      const { type, value } = target;
      switch (type) {
        case 'radio':
          const tarParentId = $(target).parents('.el-radio').attr('data-parentId');
          const brotherRadio = $(target).parent().parent().parent().siblings().children().find('input');
          Array.from(brotherRadio).forEach((item, index) => {
            dealInput.call(this, item, index, brotherRadio);
          });
          this.answerObj[tarParentId].itemId = value;
          this.answerObj = { ...this.answerObj };
          break;
        case 'checkbox':
          if (!target.checked) {
            const brotherCheckbox = $(target).parent().parent().parent().find('.problem_com').find('input');
            Array.from(brotherCheckbox).forEach((item, index) => {
              dealInput.call(this, item, index, brotherCheckbox);
            });
          }
          break;
      }
      function dealInput(item, index, brotherList) {
        const { value: itValue, type: itType } = item;
        if (itType === 'radio') {
          const parentId = $(brotherList[index]).parents('.el-radio').attr('data-parentId');
          if (this.answerObj[parentId]) {
            this.answerObj[parentId].itemId = '';
          }
        } else if (itType === 'checkbox') {
          this.multipleAnswerObj[itValue].checked = false;
          this.multipleAnswerObj = { ...this.multipleAnswerObj };
        }
      }
    },
    selectedClinical(ev) {
      const target = ev.target;
      const type = target.getAttribute('data-type');
      if (type) {
        this.tabType = type;
      }
    },
    getPreferAnswerList(config) {
      Promise.all([
        problemStatement(),
        preferAnswerList(config)
      ]).then(([problemList, answerList]) => {
        this.isLoading = false;
        let cList = [], pList = [];
        for (let i = 0, len = problemList.length; i < len; i++) {
          if (problemList[i].category === 'clinic') {
            cList.push(problemList[i]);
          } else if (problemList[i].category === 'product') {
            pList.push(problemList[i]);
          }
        }
        this.clinicProblemObj = {
          clinic: cList,
          product: pList
        };
        const inputAnswerObj = {};
        const mObj = {};
        const aObj = {};
        recursiveFn(problemList);
        function recursiveFn(list) {
          for (let i = 0, len = list.length; i < len; i++) {
            dealOptions(list[i]);
            if (Array.isArray(list[i].preferItemList) && list[i].preferItemList.length > 0) {
              recursiveFn(list[i].preferItemList);
            }
          }
        }
        function dealOptions(item) {
          const { parentId, questionId, id, type, parentQuestionType, questionType } = item;
          const pType = parentQuestionType || type;
          if (parentQuestionType === 'multiple') {
            mObj[id] = {
              parentId,
              questionId,
              parentQuestionType,
              itemId: id,
              itemType: type,
              itemQuestionType: questionType || '',
              checked: false,
              disabled: false
            };
          } else if (pType === 'input') {
            inputAnswerObj[id] = {
              parentQuestionType,
              parentId: parentId || id,
              questionId: questionId || id,
              itemFillContent: '',
              isInput: '1',
              itemId: id,
              itemType: type,
              itemQuestionType: questionType || '',
            };
          } else if (pType === 'single') {
            aObj[parentId || id] = {
              parentQuestionType,
              parentId: parentId || questionId,
              questionId: questionId || id,
              itemId: '',
              itemType: type,
              itemQuestionType: questionType || ''
            };
          }
          if (type === 'choose_input') {
            inputAnswerObj[id] = {
              parentQuestionType,
              parentId,
              questionId,
              type,
              itemFillContent: '',
              itemId: id,
              itemType: type,
              itemQuestionType: questionType || '',
            };
          }
        }
        answerList.forEach(dealWith);
        this.answerObj = aObj;
        this.multipleAnswerObj = mObj;
        this.inputAnswerObj = inputAnswerObj;
        function dealWith(item) {
          const { parentQuestionType, parentId, questionId, itemId, itemType, itemFillContent } = item;
          let pId = parentId || questionId, iT = parentQuestionType;
          if (iT === 'single') {
            if (aObj[pId]) {
              aObj[pId].itemId = itemId;
            }
          }
          if (iT === 'multiple') {
            if (mObj[itemId]) {
              mObj[itemId].checked = true;
            }
          }
          if (iT === 'input' || itemType === 'input') {
            if (inputAnswerObj[itemId]) {
              inputAnswerObj[itemId].itemFillContent = itemFillContent;
            }
          }
          if (itemType === 'choose_input') {
            if (inputAnswerObj[itemId]) {
              inputAnswerObj[itemId].itemFillContent = itemFillContent;
            }
          }
        }
      }).catch(() => {
        this.isLoading = false;
      });
    },
    saveProblem() {
      const inputAnswerObj = JSON.parse(JSON.stringify(this.inputAnswerObj));
      const answerObj = JSON.parse(JSON.stringify(this.answerObj));
      const multipleAnswerObj = JSON.parse(JSON.stringify(this.multipleAnswerObj));
      const inputAnswerList = Object.values(inputAnswerObj).filter(item => !!((item.itemFillContent || '').trim()));
      const answerList = Object.values(answerObj).filter(item => !!item.itemId);
      const multipleAnswerList = Object.values(multipleAnswerObj).filter(item => item.checked);
      const len = answerList.length;
      const mLen = multipleAnswerList.length;
      const itemReqList = [];
      inputAnswerList.forEach(item => {
        if (item.isInput) {
          itemFn(item);
        }
      });
      for (let i = 0; i < len; i++) {
        if (inputAnswerObj[answerList[i].itemId]) {
          const item = inputAnswerObj[answerList[i].itemId];
          if (item.itemFillContent) {
            itemFn(item);
          }
        } else {
          itemFn(answerList[i]);
        }
      }
      for (let j = 0; j < mLen; j++) {
        if (inputAnswerObj[multipleAnswerList[j].itemId]) {
          const item = inputAnswerObj[multipleAnswerList[j].itemId];
          if (item.itemFillContent) {
            itemFn(item);
          }
        } else {
          itemFn(multipleAnswerList[j]);
        }
      }
      function itemFn(item) {
        const { parentId, questionId, itemFillContent = '', itemId, itemQuestionType, itemType, parentQuestionType } = item;
        itemReqList.push({
          parentId,
          questionId,
          itemFillContent,
          itemId,
          itemQuestionType,
          itemType,
          parentQuestionType
        });
      }
      const params = {
        refId: sessionStorage.getItem('refId'),
        refType: sessionStorage.getItem('refType'),
        itemReqList
      };
      this.isLoading = true;
      preferAnswerCreate(params).then(() => {
        this.isLoading = false;
        notifyMsg(this, 'success', this.$t('casesDetail.clinicalPreferences.lcphsz'), 2500);
        const backUrl = localStorage.getItem('backUrl') || '/cases';
        if (backUrl) {
          this.goPage(backUrl);
        } else {
          this.goBack();
        }
      }).catch(() => {
        this.isLoading = false;
      });
    },
    goPage(url) {
      if (url) {
        this.$router.back(-1);
      }
    },
    goBack() {
      this.$router.back(-1);
    }
  },
  components: {
    FullLoading,
    BackStep,
    ProblemCom
  },
  beforeDestroy() {
    sessionStorage.removeItem('refType');
    sessionStorage.removeItem('refId');
    localStorage.removeItem('backUrl');
    sessionStorage.removeItem('isShowRTD');
  }
}
</script>

<style scoped lang="scss">
.clinical_box {
  width: 13.44rem;
  margin: 0 auto 1rem;
  padding-top: 50px;

  .c_con_h {
    margin-bottom: 0.3rem;

    h5 {
      font-size: 0.16rem;
      color: $main_theme_color_333;
      margin-bottom: 0.04rem;
    }

    p {
      color: $main_theme_color_333;
      font-size: 0.14rem;
    }
  }

  .c_con_ul {
    margin: 0 auto;

    .c_con_li {
      margin-bottom: 0.2rem;

      .c_li_h5 {
        margin-bottom: 0.2rem;
        display: flex;
        align-items: center;
        font-size: 0.16rem;
        color: $main_theme_color_333;

        .c__h5_sp {
          color: $main_theme_color_999;
          margin-left: 0.1rem;
          font-size: 16px;
        }
      }
    }

    .single_ul {
      margin-left: 0.44rem;
    }
  }
}

.clinical_tabs {
  display: flex;
  align-items: center;

  .tabs_sp {
    cursor: pointer;
    background-color: #FAFAFA;
    border-radius: 0.06rem 0.06rem 0 0;
    padding: 16px 20px;
    font-size: 0.18rem;
    line-height: 0.25rem;
    color: $main_theme_color_999;
  }

  .tabs_sp_active {
    background-color: $main_theme_color;
    color: $main_theme_color_333;
  }
}

.clinical_con {
  background-color: #FFFFFF;
  border-radius: 0.1rem;
  padding: 30px;
}

.clinical_head {
  margin: 0.22rem 0 0.12rem;
  text-align: right;
}

.step_right {
  color: #666666;
  cursor: pointer;
  padding: 0.12rem 0;
}

.page_foo {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 102px;
  box-shadow: 0px -2px 8px 0px rgba(51, 51, 51, 0.08);
  border-radius: 0 0 8px 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  .submit_btn {
    width: 176px;
    height: 42px;
    background: $main_theme_color;
    border-radius: 6px;
    font-size: 16px;
    color: $main_theme_color_333;
  }
}</style>
