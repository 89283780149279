<template>
  <div class="problem_com">
    <div v-if="item.preferItemList && item.preferItemList.length > 0">
      <ul
        class="c_li_ul"
        v-if="(item.questionType || item.type) === 'single'"
      >
        <el-radio-group
          v-model="answerObj[item.id] && answerObj[item.id].itemId"
          :disabled="item.parentQuestionType === 'multiple'
            ? (multipleAnswerObj[item.id] && !multipleAnswerObj[item.id].checked)
            : (item.parentId && (answerObj[item.parentId] && answerObj[item.parentId].itemId !== item.id))"
        >
          <li class="li_single" v-for="item1 in item.preferItemList" :key="item1.id">
            <el-radio
              :label="item1.id"
              :data-parentId="item.id"
            >
              {{lang === 'en_US' ? item1.enContent : item1.content}}
              <input
                v-if="item1.type === 'choose_input'"
                class="input"
                type="text"
                :placeholder="$t('casesDetail.clinicalPreferences.qsr')"
                v-model="inputAnswerObj[item1.id] && inputAnswerObj[item1.id].itemFillContent"
              >
            </el-radio>
            <div v-if="item1.preferItemList && item1.preferItemList.length > 0">
              <problem-com
                :item="item1"
                :answerObj="answerObj"
                :inputAnswerObj="inputAnswerObj"
                :multipleAnswerObj="multipleAnswerObj"
              />
            </div>
          </li>
        </el-radio-group>
      </ul>
      <ul
        class="c_li_ul"
        v-if="(item.questionType || item.type) === 'multiple'"
      >
        <li class="li_single" v-for="item1 in item.preferItemList" :key="item1.id">
          <el-checkbox
            :label="item1.id"
            :disabled="!(((item.parentQuestionType || item.type) === 'single')
            ? (answerObj[item.parentId] && answerObj[item.parentId || item.id].itemId === item.id)
            : !(item.parentId && (multipleAnswerObj[item1.parentId] && !multipleAnswerObj[item1.parentId].checked)))"
            v-model="multipleAnswerObj[item1.id] && multipleAnswerObj[item1.id].checked"
          >
            {{lang === 'en_US' ? item1.enContent : item1.content}}
            <input
              v-if="item1.type === 'choose_input'"
              class="input"
              type="text"
              :placeholder="$t('casesDetail.clinicalPreferences.qsr')"
              v-model="inputAnswerObj[item1.id] && inputAnswerObj[item1.id].itemFillContent"
            >
          </el-checkbox>
          <div v-if="item1.preferItemList && item1.preferItemList.length > 0">
            <problem-com
              :item="item1"
              :answerObj="answerObj"
              :inputAnswerObj="inputAnswerObj"
              :multipleAnswerObj="multipleAnswerObj"
            />
          </div>
        </li>
      </ul>
      <ul
        class="c_li_ul"
        v-if="(item.parentQuestionType || item.type) === 'text'"
      >
        <li class="li_single" v-for="item1 in item.preferItemList" :key="item1.id">
          <div v-if="item1.preferItemList && item1.preferItemList.length > 0">
            {{lang === 'en_US' ? item1.enContent : item1.content}}
            <problem-com
              :item="item1"
              :answerObj="answerObj"
              :inputAnswerObj="inputAnswerObj"
              :multipleAnswerObj="multipleAnswerObj"
            />
          </div>
        </li>
      </ul>
    </div>
    <ul class="c_li_ul"
      v-if="(item.parentQuestionType || item.type) === 'input'">
      <li class="li_single">
        <el-input
          type="textarea"
          rows="3"
          v-model="inputAnswerObj[item.id] && inputAnswerObj[item.id].itemFillContent"
        />
      </li>
    </ul>
  </div>
</template>

<script>
  import {problemStatement, preferAnswerList} from 'common/api/cases';
  import {getUserId} from 'common/js/util';
  export default {
    name: 'problemCom',
    data() {
      return {
        proAnswerObj: {},
        proInputAnswerObj: {}, // 输入框
        proMultipleAnswerObj: {}, // 多选
        problemObj: {
          single: this.$t('casesDetail.clinicalPreferences.dx'),
          multiple: this.$t('casesDetail.clinicalPreferences.duox'),
          input: ''
        },
        lang: localStorage.getItem('user_lang') || 'zh_CN'
      }
    },
    created() {},
    props: {
      item: {
        type: Object,
        default: () => ({})
      },
      answerObj: {
        type: Object,
        default: () => ({})
      },
      inputAnswerObj: {
        type: Object,
        default: () => ({})
      },
      multipleAnswerObj: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>

<style scoped lang="scss">
  .problem_com{
    margin-left: 0.3rem;
  }
  .c_li_ul {
    .c_li_h5{
      line-height: 0.21rem;
      font-weight: bold;
      margin-bottom: 0.16rem;
      display: flex;
      align-items: center;
      .c__h5_sp{
        color: $main_theme_color_999;
        line-height: 0.21rem;
        margin-left: 0.06rem;
      }
    }
    .li_single{
      color: $main_theme_color_333;
      font-size: 0.14rem;
      line-height: 0.16rem;
      margin-bottom: 0.2rem;
      .input{
        border: 0.01rem solid #ccc;
        border-radius: 0.04rem;
        padding: 0.04rem 0 0.04rem 0.05rem;
        font-size: 0.13rem;
        color: $main_theme_color_333;
      }
      span{
        color: #999999;
        font-size: 0.14rem;
      }
    }
    li:last-child{
      margin-bottom: 0 !important;
    }
  }
  /deep/ .el-radio__label{
    color: $main_theme_color_333 !important;
  }
</style>
